import React from 'react';
import Product from './Product';

export default function ProductList({ products = [], selectedProductId, setSelectedProductId, setShow, activeCategory }) {
    console.log("ACTIVE CAT", activeCategory)
    return (
        <div className="app__right__products">
            <h1 className="app__right__products__entry-title">{Object.keys(activeCategory).length === 0 ? 'No cats' : activeCategory.name}</h1>
            <div className="row ">
                {products.map((product, i) => (
                    <Product key={i} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} setShow={setShow} {...product} />
                ))}
            </div>
        </div>
    );
}