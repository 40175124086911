import React, { useState } from 'react'

import { useUserContext } from '../../contexts/UserContext'
import { Form, Button } from 'react-bootstrap'

import {alertService} from '../../services';

import { useAppContext } from "../../libs/contextLib";
import { withAsync } from '../../api/helpers'
import { login, logout, setAuthToken } from '../../api/authApi'
import { fetchUser} from '../../api/restaurantApi'

import Alert from '../../components/Alert'


export default function Login(props) {

    //const history = useHistory();

    const user = useUserContext()
    console.log('user context', user)
    const { userHasAuthenticated } = useAppContext();
    const [email, changeEmail] = useState('');
    const [password, changePassword] = useState('');

    // function validateForm() {
    //     return email.length > 0 && password.length > 0;
    // }

    async function onSubmit(e) {
        e.preventDefault();
        const { response, error } = await withAsync(login, email, password)
        console.log('login response', response, error)

        if (error) {
            console.log('error', error)
            alertService.error('E-mail of gebruikersnaam onjuist<br />' + error)
            return
        }

        alertService.success('Welkom', { keepAfterRouteChange: true });

        const { key } = response.data
        localStorage.setItem("token", key)
        const token = localStorage.getItem("token")
        setAuthToken(token)
        
        userHasAuthenticated(true)

        console.log('===> USER DATA', response.data)
        console.log('HALLOOO FETCH USER DATAS')
        const { response: userResponse, error: userError } = await withAsync(fetchUser)
        console.log({userResponse, userError, response, error})
        if (userError) {
            console.log('error', error)
            alertService.error(error)
            return
        }
        const userData = userResponse.data?.[0]
        //console.log('===> USER DATA ', userData)

        //console.log('===> USER NAME ', userData.name)
        user.setName(userData.name)

        //console.log('===> USER RESTAURANT ', userData.restaurant)
        user.setRestaurant(userData.restaurant)
        alertService.success('All orders deleted', { keepAfterRouteChange: true });
        if(token){
            props.history.push("/home")
            // window.location = '/home'
        }
    }

    // async function initLogout() {
    //     const { response, error } = await withAsync(logout)
    //     console.log('Logout', response, error)
    //     if (error) {
    //         console.log('error', error)
    //         return
    //     }
    //     console.log('Logout success')
    //     userHasAuthenticated(false)
    // }

    return (
        <div id="Outer">
            <main id="ContentBox">
                <div className="app">
                    <div className="container h-100">
                        <div className="row mt-10 justify-content-center align-items-center">
                            
                            <div className="form col-10 col-md-8 col-lg-6 mt-5">
                            <Alert />
                                <h1>Login</h1>
                                <p className="description">Solufo RMS</p>
                                <Form onSubmit={onSubmit}>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control autoFocus 
                                            onChange={(e) => changeEmail(e.target.value)}
                                            value={email}
                                            type={'input'}
                                            name="email" />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control onChange={(e) => changePassword(e.target.value)}
                                            value={password}
                                            type={'password'}
                                            name="password" />
                                    </Form.Group>
                                    <Button variant="primary" type={'submit'}>
                                        Login
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div >
    );
}