

export default function OrderItem({ id, qty, name, productIndex, totalPrice, orderList, setOrderList, order_item_options, optionList }) {

    const deleteItem = () => {
        setOrderList(orderList.filter((_, index) => index !== productIndex));
    }

    const deductItem = () => {
        setOrderList(orderList => {
            const products = orderList.map((product, index) => {
                if (index === productIndex) {
                    const { qty } = product
                    if (qty === 1) {
                        return product
                    }
                    return {
                        ...product,
                        qty: qty - 1
                    }
                }
                return product
            })
            return products
        })
    }

    const incremenItem = () => {
        setOrderList(orderList => {
            const products = orderList.map((product, index) => {
                if (index === productIndex) {
                    return {
                        ...product,
                        qty: product.qty + 1
                    }
                }
                return product
            })

            return products
        })
    }

    console.log('length' + optionList.length)
    /*
        {
            one: 'banana',
            two: 'apple'
        }

        Object.entries(obj)

        [
            ['one', 'banana'],
            ['two', 'apple']
        ]

        [
            ['1', {id: '1', name: 'option name'}],
            ['1', {id: '1', name: 'option name'}]
        ]
    

    */

    return (
        <div className="app__left__sell__item">
            <div className="app__left__sell__item__detail">
                <span className="app__left__sell__item__detail__qty" >{qty}x</span >
                <span className="app__left__sell__item__detail__name" >{name}</span >
                <span className="app__left__sell__item__detail__price" >€{totalPrice * qty}</span >
                <span className="app__left__sell__item__detail__btngroup">
                    <button className="button btnplus" onClick={deductItem}></button>
                    <button className="button btnminus" onClick={incremenItem} ></button >
                    <button className="button btndel" onClick={deleteItem}></button>
                </span>
            </div>
            <div className="app__left__sell__item__addons">
                {Object.entries(order_item_options).map(([optionId, option]) => (
                    <span className="app__left__sell__item__addons__list" key={optionId}>+ {option.name}</span>
                ))}
            </div>
        </div>
    )
}