import React from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import Nav from '../../components/Nav'
import Alert from '../../components/Alert'
import Home from '../../components/admin/Home'
import Catagories from '../../components/admin/catagories/Index'
import Products from '../../components/admin/products/Index'
import ProductOptions from '../../components/admin/product-options/Index'
import Orders from '../../components/admin/orders/Index'


export default function Admin() {

    const { pathname } = useLocation();  
    // useState to set Model (category, product or productOptions)

    return (
      <div className="app-container bg-light">
      <Nav />
      <Alert />
      <div className="container pt-4 pb-4">
        <Switch>
            <Route exact path="/admin" component={Home} />
            <Route path="/admin/catagories" component={Catagories} />
            <Route path="/admin/products" component={Products} />
            <Route path="/admin/product-options" component={ProductOptions} />
            <Route path="/admin/orders" component={Orders} />
        </Switch>
      </div>
  </div>
    )
}