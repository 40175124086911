

export default function OrderReceipt( {orderList, totalPrice} ) {
    return (
        <div>
        { orderList.map((product, i) => (
        <div className="app__left__sell__item">
            <div className="app__left__sell__item__detail">
                <span className="app__left__sell__item__detail__qty" >{product.qty}x</span >
                <span className="app__left__sell__item__detail__name" >{product.name}</span >
                <span className="app__left__sell__item__detail__price" >€{product.totalPrice * product.qty}</span >
            </div>
            <div className="app__left__sell__item__addons">
                {Object.entries(product.order_item_options).map(([optionId, option]) => (
                    <span className="app__left__sell__item__addons__list" key={optionId}>+ {option.name}</span>
                ))}
            </div>
        </div>
        ))}
        <div>
            <h4>Totaal: {totalPrice}</h4>
        </div> 
        </div>
    )
}

