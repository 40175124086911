import React from "react";

export default function Product({ id, name, price, photo, setSelectedProductId, setShow }) {

    const productDetailShow = () => setShow(true);

    // TEMP while in dev
    const product_image = require('../images/product-img.jpg').default

    return (
        <div className="app__right__products__item col-md-6 col-xl-3">
            <a className="app__right__products__item__wrap" onClick={() => {
                setSelectedProductId(id);
                productDetailShow();
            }
            } href="#" >
                {photo ? <img className="img-app__right__products__item__img" src={photo} alt=""/> : <img src={product_image} alt="" className="app__right__products__item__img" />}
                {/* <img src={product_image} alt="" className="app__right__products__item__img" /> */}
                <h4 className="app__right__products__item__title">{name}</h4>
                {/* <span className="app__right__products__item__naam">subtitle</span> */}
                <span className="app__right__products__item__price">€{price}</span>
            </a>
        </div>
    )
}