import React, {useContext} from 'react'

import { UserContext } from '../contexts/UserContext'
import { useState, useEffect } from 'react'
import { withAsync } from '../api/helpers'
import {alertService} from '../services';

import { fetchRestaurantCategories, fetchRestaurantProducts } from '../api/restaurantApi'

import Alert from '../components/Alert'
import Categories from '../components/Categories'
import ProductDetail from '../components/ProductDetail'
import ProductList from '../components/ProductList'
import Order from '../components/Order'

export default function Home() {

   // const token = localStorage.getItem("token")

    // Hooks
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState({})
    const [products, setProducts] = useState([]);
    // const [newOrderList, setNewOrderList] = useState(orderList)
    const [listProducts, setListProducts] = useState([])

    // Product detail modal
    const [show, setShow] = useState(false);
    const productDetailClose = () => setShow(false);
    
    /* Categories */
    async function fetchCategories() {
        const {error, response } = await withAsync(fetchRestaurantCategories)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        setCategories(response.data)
        const firstCat = response.data[0]
        setActiveCategory(firstCat)
    }

    useEffect( () => {
        fetchCategories()
    }, []);

    /* Products */
    async function fetchProducts() {
        const {error, response } = await withAsync(fetchRestaurantProducts)
        //console.log('Fetch products response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        setProducts(response.data)
        setListProducts(response.data)
    }

    // Inital loading products
    useEffect(() => {
        fetchProducts()
    }, []);

    // Update product list when selecting a category
    useEffect(() => {
        const productListByCat = products.filter(x => x.category === activeCategory.id)
        setListProducts(productListByCat)
    }, [activeCategory]);


    const user = useContext(UserContext)
    // console.log('===> User ', user)
    //console.log('listProducts ', listProducts)
    // console.log('categories loaded?', categories)
    // console.log('products loaded?', products)
    // console.log('===> ORDERLIST IN HOME ' + orderList)
    // console.log('length array is ' + orderList.length)
    // console.log('ActiveCat ', activeCat)


    return (
        <div id="Outer">
            <main id="ContentBox">
                <div className="app">
                    <div className="container">
                        <Alert />
                        <div className="d-flex">
                            <Order orderList={orderList} setOrderList={setOrderList} />
                            <div className="app__right media-body">
                                <Categories categories={categories} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
                                <ProductList products={listProducts} selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} categories={categories} activeCategory={activeCategory} setShow={setShow} />
                            </div>
                        </div>
                    </div>
                </div>
                <ProductDetail products={products} selectedProductId={selectedProductId} orderList={orderList} setOrderList={setOrderList} show={show} productDetailClose={productDetailClose} />
            </main>
        </div >
    );
}