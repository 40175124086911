import { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import { Modal, Button } from 'react-bootstrap'

/*
// import default
import ProductDetail from './ProductDetail.js'
// import named 
import { msg } from './ProductDetail'
// import both default and named
import ProductDetail, { msg } from './ProductDetail'
// import all under alias
import * as productSomething from './ProductDetail'

// named individual exports
export const msg = 'hello world'
export const fruit = 'banana'

const msg = 'hello world'
const fruit = 'banana'

export {
    msg,
    fruit
}*/


export default function ProductDetail({ products = [], orderList, selectedProductId, setOrderList, show, productDetailClose }) {

    const [product, setProduct] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0)
    const [selectedOptions, setSelectOptions] = useState({})
    const [api_selected_options, set_api_selected_options] = useState([])
    const [optionList, setOptionList] = useState([{}])

    // Set new product to handle
    useEffect(() => {
        if (!selectedProductId) return
        //console.log('selected product id', { selectedProductId })
        const product = products.find(p => p.id === selectedProductId)
        //console.log({ product })
        const clonedProduct = cloneDeep(product)

        setProduct(clonedProduct)
        setTotalPrice(clonedProduct.price)
        setSelectOptions({})
    }, [selectedProductId])


    // setTotalPrice on basis of product options
    useEffect(() => {
        if (!product) return
        const productOptionsTotalPrice = product.options.reduce((acc, option) => {
            const { id, price } = option
            // If the option is selected, then add its price to the total
            if (selectedOptions[id]) {
                acc += parseFloat(price)
            }
            return acc
        }, 0)

        //console.log('total?', productOptionsTotalPrice, product.price)
        setTotalPrice((parseFloat(product.price) + productOptionsTotalPrice).toFixed(2))
    }, [selectedOptions])


    const addProductToOrder = (product) => {
        const order_item_options = Object.entries(selectedOptions).reduce((acc, [optionId, isSelected]) => {
            if (!isSelected) return acc
            const optionDetails = product.options.find(item => item.id == optionId)
            acc[optionId] = {
                ...optionDetails
            }
            return acc
        }, {})
        
        setOrderList(orderList => (
            [
                ...orderList,
                {
                    //...product,
                    name: product.name,
                    product: product.id,
                    qty: 1,
                    totalPrice,
                    order_item_options,
                    api_selected_options,
                    optionList: [optionList],
                    orderProductId: uuidv4()
                }
            ]
        ))
    }

    console.log('===> OrderList', orderList)
    console.log('===> selectedOptions', selectedOptions)

    // Handle toggle of product option
    const onProductOptionChange = (e, productOptionId, optionName) => {
        //console.log('on change', productOptionId, e.target.checked)
        const { checked } = e.target
        let newSelectedOptions = { ...selectedOptions }
        let newOptionList = { ...optionList }
        if (checked) {
            newSelectedOptions[productOptionId] = {
                [optionName]: true,
                order_item : productOptionId,
            };
            newSelectedOptions['product_option'] = productOptionId.id;
            // newOptionList[productOptionId] = {
            //     name: optionName
            // }

        } else {
            delete newSelectedOptions[productOptionId];
            delete newOptionList[productOptionId];
        }
        console.log('===> NEW SELECTED OPTIONS', { newSelectedOptions })
        setSelectOptions(newSelectedOptions);
        //setOptionList(optionlistnewOptionList)
    }


    // temp use of images while in dev
    let option_style = {
        backgroundImage: "url(" + require('../images/popup-addons-item-icn3.jpg').default + ")",
    }

    return (

        <Modal show={show} onHide={productDetailClose} className="right fade" tabIndex="-1" aria-labelledby="mymodallLabel" aria-hidden="true">

            <div className="popup modal-dialog">
                <div className="popup__info d-flex flex-column modal-content">
                    {!product ?
                        <>
                            <p>Nothing to see here</p>
                        </>
                        :
                        <>
                            <div className="popup__sticktop">
                                <div className="popup__tooltip">
                                    <Button type="button" className="popup__tooltip__button" data-toggle="tooltip" data-html="true" data-placement="left" title="<em>Tooltip</em> <u>with</u> <b>HTML</b>"><i className="fa fa-info" aria-hidden="true"></i></Button>
                                </div>
                                <div className="popup__image text-center">
                                    <div className="popup__image__wrap">
                                    {product.photo_thumbnail ? <img className="img-app__right__products__item__img" src={product.photo_thumbnail} alt=""/> : <img src={require('../images/product-img.jpg').default} alt="" />}
                                    </div>
                                </div>
                                <div className="popup__title text-center">
                                    <h3>{product.name}</h3>
                                    <span className="popup__title__naam">{totalPrice}</span>
                                </div>
                                <div className="popup__addons" id="popupaddonsscroll">
                                    {product.options.map((option, i) => (

                                        <label className="popup__addons__item" key={option.id}>
                                            {/* <span className="popup__addons__item__icn" style={option_style}></span> */}
                                            <span className="popup__addons__item__name">{option.name} {option.price}</span>
                                            <input type="checkbox" className="popup__addons__item__check" checked={selectedOptions[option.id] || false} onChange={e => onProductOptionChange(e, option.id, option.name)} />
                                        </label>

                                    ))}

                                </div>
                            </div>
                            <div className="popup__stickbottom">
                                <div className="popup__bestelling text-center">
                                    <a href="#" onClick={() => {
                                        addProductToOrder(product);
                                        productDetailClose();
                                    }} className="btn btn-primary popup__bestelling__action">Voeg toe aan bestelling</a>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
        </Modal >

    )
}
