import React from 'react'
import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { cloneDeep } from 'lodash-es'
import { withAsync } from '../../api/helpers'

import {fetchOrdersWithStatus, patchOrder} from '../../api/restaurantApi'

export default function ActiveOrders() {

    const logo = require('../../images/logo.jpg').default

    // Fetch order data
    const [orders, setOrders] = useState([]);
    async function fetchOrders() {
        const status = 'created'
        const {error, response } = await withAsync(fetchOrdersWithStatus, status)
        console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        setOrders(response.data)
    }

    useEffect( () => {
        fetchOrders()
        const interval = setInterval(fetchOrders, 10000)
        return () => clearInterval(interval)
    }, []);

    // Set specific order the handle by orderId
    const [selectedOrderid, setSelectedOrderId] = useState(null)
    const [order, setOrder] = useState(null)

    // set Order infromation on basis of selectedOrderId
    useEffect(() => {
        if (!selectedOrderid) return
        console.log('UseEffect - Selected order id is, ', { selectedOrderid });
        const order = orders.find(o => o.id === selectedOrderid)
        console.log({ order })

        const clonedOrder = cloneDeep(order)
        setOrder(clonedOrder)
    }, [selectedOrderid])

    // Order detail modal handling
    const [showOrderModal, setShowOrderModal] = useState(false);
    const orderModalClose = () => setShowOrderModal(false);
    const orderModalShow = () => setShowOrderModal(true);

    // Update order status
    const onUpdateOrder = async (orderId) => {
        console.log('===> ORDER_ID IN onUpdateORder:', orderId)
        try {
            const payload = {
                id: orderId,
                status: 'ready',
            }
            console.log('===> PAYLOAD', {payload})
            
            // make api request
            const {error, response} = await withAsync(patchOrder,orderId, payload)
            console.log('PATCH ORDER RESPONSE', response, error)
            if (error) {
                console.log('ERROR', error)
                return
            } else {
                console.log('===> RESPONSE', response)
            }        
            //orderModalClose()
        } catch (error) {
            console.error(error)
        }
    }

    //Logs
    //console.log('===> ORDERS', orders)

    return (
        <div id="Outer">
            <main id="ContentBox">
                <header className="header">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="header__logo">
                                <a href="#">
                                    <img src={logo} alt="" />
                                </a>
                            </div>
                            <div className="header__active">
                                <h5>Active orders</h5>
                            </div>
                            <div className="header__copyright">
                                <p>&copy; 2021 Solufo RMS</p>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="order">
                    <div className="container-fluid">
                        <div className="order__list" id="orderlistscroll">

                            {orders.map((order, i) => (
                                <div className="order__list__item">
                                    <a data-toggle="modal" data-target="#orderModal" className="order__list__item__wrap" onClick={
                                        () => {
                                            setSelectedOrderId(order.id)
                                            orderModalShow()
                                        }
                                    } key={i}>
                                        <div className="order__list__item__top order__list__item__top--here">
                                            <h4 className="order__list__item__top__title">Order #{order.id}</h4>
                                            <span className="order__list__item__top__type">hier opeten</span>
                                        </div>
                                        <div className="order__list__item__detail">
                                            {/* <span className="order__list__item__detail__date">09:57 / 20-01-2021</span> */}

                                            {order.order_items.map((item, i) => ( 
                                            <div className="order__list__item__detail__item" key={item.id}>
                                                <div className="order__list__item__detail__productname">
                                                    <span className="order__list__item__detail__qty">{item.qty}x</span>
                                                    <span className="order__list__item__detail__name">{item.product_name}</span>
                                                </div>
                                                <div className="order__list__item__detail__addons">
                                                    {item.order_item_options.map((option, i) => (
                                                    <>
                                                    <span className="order__list__item__detail__addons__item" key={option.product_option}>+ {option.product_option_name}</span>
                                                    </>
                                                    ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Modal show={showOrderModal} onHide={orderModalClose} className="modal fade" id="orderModal" tabIndex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
                    {!order ?
                        <p>No order selected</p>
                        :
                        < div className="orderDetail modal-dialog modal-dialog-centered">
                            <div className="orderDetail__wrap modal-content">
                                <div className="orderDetail__top orderDetail__top--here">
                                    <h3 className="orderDetail__top__title">Order #{order.id}</h3>
                                </div>
                                <div className="orderDetail__bg">
                                    {/* <span className="orderDetail__date">09:57 / 20-01-2021</span> */}
                                    <div className="row">
                                        {order.order_items.map((item, i) => (
                                            <div key={i} className="orderDetail__item col-6">
                                                <div className="orderDetail__item__productname">
                                                    <span className="orderDetail__item__qty">{item.qty}x</span>
                                                    <span className="orderDetail__item__name">{item.product_name}</span>
                                                </div>
                                                <div className="orderDetail__item__addons">
                                                {item.order_item_options.map((option, i) => (
                                                    <>
                                                    <span className="orderDetail__item__addons__item" key={i}>+ {option.product_option_name}</span>
                                                    </>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="text-center orderDetail__button">
                                        <a href="" className="btn btn-primary orderDetail__action" onClick={
                                            () => {
                                                onUpdateOrder(order.id)
                                            }
                                        }>order ready</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Modal>

            </main>
        </div >
    )
} 