import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {alertService} from '../../../services';
import {postRestaurantCrudProducts, fetchRestaurantCrudProductById, patchRestaurantCrudProducts, fetchRestaurantCategories, fetchRestaurantProductOptions} from '../../../api/restaurantApi'
import { withAsync } from '../../../api/helpers'

export default function AddEdit({ history, match }) {


    const { id } = match.params;
    const isAddMode = !id;
    
    const [product, setProduct] = useState({});
    const [restaurantProductOptions, setRestaurantProductOptions] = useState([])
    //const [productOptions, setProductOptions] = useState([])
    const [catagories, setCatagories] = useState([])
    //const [currentCatagory, setCurrentCat] = useState(null)
    const [selectedOptions, setSelectedOptions] = useState([])

    const restaurantId = 2 //Fix with context ASAP

    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
        price: Yup.number()
            .required('Price is required'),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        
        /*const payload = {
            restaurant: 2, //TODO fetch user data
            name : data.name,
            price : data.price,
            category : data.category,
            options : data.options,
            photo : data.photo
        }*/
        const payload = new FormData()
        payload.append('restaurant', 2)
        payload.append('name', data.name)
        payload.append('price', data.price)
        payload.append('category', data.category)
        payload.append('options', data.options)
        if (data.photo.length > 0) {
            payload.append("photo", data.photo[0]);
        }

        console.log('===> PAYLOAAAD', payload)
        return isAddMode
            ? createProduct(payload)
            : updateProduct(id, payload);
    }

    async function fetchProduct(id) {
        if (!isAddMode) {
            const {error, response} = await withAsync(fetchRestaurantCrudProductById, id)
            //console.log('Fetch categories response', response, error)
            if (error) {
                console.log('error', error)
                return
            }
            //console.log('===>  FETCHCAT RESPONSE', response)
            const fields = ['name', 'price', 'category', 'options'];
            fields.forEach(field => setValue(field, response.data[field]));
            setProduct(response.data)
            setSelectedOptions(response.data.options)
        }
    }

    useEffect(() => {
        fetchProduct(id)
        fetchCatagories(restaurantId)
        fetchRestaurantOptions()
    },[]);
    

    async function createProduct(data) {
        //console.log('===> THIS IS DATA', data)
        const {error, response } = await withAsync(postRestaurantCrudProducts, data)
        //console.log('Fetch categories response', response, error)
        if (error) {
            alertService.error(error)
            return
        }
        console.log('response', response)
        alertService.success('Product added', { keepAfterRouteChange: true });
        history.push('.');
    }

    async function updateProduct(id, data) {
        console.log('WE RUNNING updateProduct', updateProduct)
        const {error, response } = await withAsync(patchRestaurantCrudProducts, id, data)
        if (error) {
            alertService.error(error)
            return
        }
        console.log('response', response)
        alertService.success('Product updated', { keepAfterRouteChange: true });
        history.push('..');
    }

    async function fetchCatagories(restaurantId) {
        const {error, response } = await withAsync(fetchRestaurantCategories, restaurantId)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        const product_catagories = response.data.map((item) => {
            const {id, name} = item

            return {
                id,
                name,
            }})
        setCatagories(response.data)
    }

    async function fetchRestaurantOptions() {
        const {error, response } = await withAsync(fetchRestaurantProductOptions)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }

        // const restaurantOptions = response.data.map((item) => {
        //     const {id, name} = item

        //     return {
        //         id,
        //         name,
        //     }})
        setRestaurantProductOptions(response.data)
    }

    function addSelectedOptions(event) {
        const newSelectedOptions = [...event.target.selectedOptions].map(o => o.value) //But whut?
        setSelectedOptions(newSelectedOptions)
      }


    // console.log('===>  CATAGORIES ', catagories)
    console.log('===>  PRODUCT ', product)
    // console.log('===>  RESTAURANT-PRODUCT-OPTIONS ', restaurantProductOptions)
    // console.log('===>  CATEGORY ', product.category)
    //console.log('===>  SELECTED-OPTIONS ', selectedOptions)

    //{...selectedOptions.includes(pOption.id) ? 'selected' : 'none'}


    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <h1>{isAddMode ? 'Add Product' : 'Edit Product'}</h1>
            <div className="form-row">
                <div className="form-group col-4">
                    <label>Product Name</label>
                    <input name="name" type="text" ref={register} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="form-group col-4">
                    <label>Price (€)</label>
                    <input name="price" type="text" ref={register} className={`form-control ${errors.price ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.price?.message}</div>
                </div>
                <div className="form-group col-4">
                <label>Categorie</label>
                    <select name="category" ref={register} className={`form-control ${errors.catagory ? 'is-invalid' : ''}`}>
                        { catagories.map((cat, key) => (
                            <option key={key} value={cat.id} >{cat.name}</option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{errors.category?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label>Product options</label><br /><small>ctrl + select, for multiple options</small>
                        <select multiple class="form-control" id="" name="options" ref={register} onChange={event => addSelectedOptions(event)} defaultValue={selectedOptions} className={`form-control ${errors.name ? 'is-invalid' : ''}`}>
                            { 
                                restaurantProductOptions.map((pOption, key) => (
                                <option key={key} value={pOption.id} selected={selectedOptions.includes(pOption.id) ? true : false}>{pOption.name}</option>
                                ))}
                        </select>
                        {/* <input name="options" type="text" ref={register} className={`form-control ${errors.name ? 'is-invalid' : ''}`} /> */}
                    <div className="invalid-feedback">{errors.options?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label for="exampleFormControlFile1">Product foto upload</label>
                    <input type="file" class="form-control-file" ref={register} id="photo" name="photo" />
                </div>
                <div className="form-group col-6">
                    <label for="exampleFormControlFile1">Product foto</label>
                    <div><img src={product.photo_thumbnail} /></div>
                </div>
            </div>
            <div className="form-row">
            <div className="form-group mt-4">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
        </div>
        </form>

    )
}