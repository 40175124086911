import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {alertService} from '../../../services';
import {fetchOrders, deleteOrderById, deleteAllOrders} from '../../../api/restaurantApi'
import {withAsync} from '../../../api/helpers'

export default function List({ match }) {
    const { path } = match;
    //const [users, setUsers] = useState(null);
    const [orders, setOrders] = useState(null);

    async function fetchRestaurantOrders() {
        const {error, response } = await withAsync(fetchOrders)
        if (error) {
            console.log('error', error)
            return
        }
        setOrders(response.data)
    }

    async function deleteAll() {
        const {error, response} = await withAsync(deleteAllOrders)
        if (error) {
            console.log('error', error)
            return
        }
        console.log(response)
        alertService.success('All orders deleted', { keepAfterRouteChange: true });
    }

    useEffect( () => {
        fetchRestaurantOrders()
    }, []);

    async function deleteOrder(id) {
        setOrders(orders.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));

        const {error, response } = await withAsync(deleteOrderById, id)
        if (error) {
            console.log('error', error)
            return
        }
        setOrders(orders => orders.filter(x => x.id !== id));
    }


    return (
        <div>
            <h1>Orders</h1>
            <button onClick={() => deleteAll()}className="btn btn-sm btn-danger float-right mb-2">Delete all</button>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '40%' }}>ID</th>
                        <th style={{ width: '40%' }}>Status</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {orders && orders.map(order =>
                        <tr key={order.id}>
                            <td>{order.id}</td>
                            <td>{order.status}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/edit/${order.id}`} className="btn btn-sm btn-primary mr-1">Edit</Link>
                                <button onClick={() => deleteOrder(order.id)} className="btn btn-sm btn-danger btn-delete-user" disabled={order.isDeleting}>
                                    {order.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span>Delete</span>
                                    }
                                </button>
                            </td>
                        </tr>
                    )}
                    {!orders &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {orders && !orders.length &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="p-2">No orders</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}