import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {alertService} from '../../../services';
import {postOrder, fetchOrderById, patchOrder} from '../../../api/restaurantApi'
import { withAsync } from '../../../api/helpers'

export default function AddEdit({ history, match }) {

    const { id } = match.params;
    const isAddMode = !id;
    
    const [order, setOrder] = useState({});

    // form validation rules 
    const validationSchema = Yup.object().shape({
        status: Yup.string()
            .required('Status is required'),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        
        const payload = {
            restaurant: 2,
            status : data.status
        }
        return isAddMode
            ? createOrder(payload)
            : updateOrder(id, payload);
    }

    async function createOrder(data) {
        const {error, response } = await withAsync(postOrder, data)
        //console.log('Fetch categories response', response, error)
        if (error) {
            alertService.error(error)
            return
        }
        alertService.success('Order added', { keepAfterRouteChange: true });
        history.push('.');
    }

    function updateOrder(id, data) {
        const {error, response } = withAsync(patchOrder, id, data)
        console.log('===> DATA', data)
        if (error) {
            alertService.error(error)
            return
        }
        alertService.success('Order added', { keepAfterRouteChange: true });
        history.push('..');
    }
    
    async function fetchOrder(id) {
        if (!isAddMode) {
            const {error, response } = await withAsync(fetchOrderById, id)
            //console.log('Fetch categories response', response, error)
            if (error) {
                console.log('error', error)
                return
            }
            const fields = ['id', 'status'];
            fields.forEach(field => setValue(field, response.data[field]));
            setOrder(response.data)
        }
    }

    useEffect(() => {
        fetchOrder(id)
    },[]);

    console.log('===> ORDER', order)

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <h1>{isAddMode ? 'Add Order' : 'Edit Order'}</h1>
            <div className="form-row">
                <div className="form-group col-1">
                    <label>Order #</label>
                    <input name="id" type="text" ref={register} readOnly className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="form-group col-4">
                    <label>Status</label>
                    <select name="status" ref={register} className={`form-control ${errors.status ? 'is-invalid' : ''}`}>
                            <option key="created" value="created" >Created</option>
                            <option key="ready" value="ready" >Ready</option>
                            <option key="done" value="done" >Done</option>
                    </select>
                    <div className="invalid-feedback">{errors.status?.message}</div>
                </div>
            </div>
            <div className="form-row">
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
        </div>
        </form>
    )
}