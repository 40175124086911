import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {alertService} from '../../../services';
import {postRestaurantCategories, fetchRestaurantCategoryById, patchRestaurantCategories} from '../../../api/restaurantApi'
import { withAsync } from '../../../api/helpers'

export default function AddEdit({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required'),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, getValues, errors, formState } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        
        const payload = {
            restaurant: 2,
            name : data.name
        }
        return isAddMode
            ? createCategory(payload)
            : updateCategory(id, payload);
    }

    async function createCategory(data) {
        //console.log('===> THIS IS DATA', data)
        const {error, response } = await withAsync(postRestaurantCategories, data)
        //console.log('Fetch categories response', response, error)
        if (error) {
            alertService.error(error)
            return
        }
        alertService.success('Category added', { keepAfterRouteChange: true });
        history.push('.');
    }

    function updateCategory(id, data) {
        console.log('WE RUNNING updateCategory', updateCategory)
        const {error, response } = withAsync(patchRestaurantCategories, id, data)
        if (error) {
            alertService.error(error)
            return
        }
        alertService.success('Category added', { keepAfterRouteChange: true });
        history.push('..');
    }

    const [category, setCategory] = useState({});
    
    async function fetchCategory(id) {
        if (!isAddMode) {
            const {error, response } = await withAsync(fetchRestaurantCategoryById, id)
            //console.log('Fetch categories response', response, error)
            if (error) {
                console.log('error', error)
                return
            }
            console.log('===>  FETCHCAT RESPONSE', response)
            const fields = ['name'];
            fields.forEach(field => setValue(field, response.data[field]));
            setCategory(response.data)
        }
    }

    useEffect(() => {
        fetchCategory(id)
    },[]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
            <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1>
            <div className="form-row">
                <div className="form-group col-5">
                    <label>Category Name</label>
                    <input name="name" type="text" ref={register} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
            </div>
            <div className="form-row">
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
        </div>
        </form>
    )
}