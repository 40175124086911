import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {fetchRestaurantCategories, deleteRestaurantCategoryById} from '../../../api/restaurantApi'
import {withAsync} from '../../../api/helpers'

export default function List({ match }) {
    const { path } = match;
    //const [users, setUsers] = useState(null);
    const [catagories, setCatagories] = useState(null);

    
    // useEffect(() => {
    //     userService.getAll().then(x => setUsers(x));
    // }, []);

    async function fetchCategories() {
        const {error, response } = await withAsync(fetchRestaurantCategories)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        console.log('===>  FETCHCAT RESPONSE', response)
        setCatagories(response.data)
        // console.log(response, "<===== Category data")
    }

    useEffect( () => {
        fetchCategories()
    }, []);

    async function deleteCategory(id) {
        setCatagories(catagories.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));

        const {error, response } = await withAsync(deleteRestaurantCategoryById, id)
        if (error) {
            console.log('error', error)
            return
        }
        console.log('THIS RESPONSE OF DELETE', response)
        setCatagories(categories => categories.filter(x => x.id !== id));
        // userService.delete(id).then(() => {
        //     setUsers(users => users.filter(x => x.id !== id));
        // });
    }

    // function deleteCategory(id) {
    //     setCatagories(catagories.map(x => {
    //         if (x.id === id) { x.isDeleting = true; }
    //         return x;
    //     }));
    //     userService.delete(id).then(() => {
    //         setUsers(users => users.filter(x => x.id !== id));
    //     });
    // }

    return (
        <div>
            <h1>Catagories</h1>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Add Category</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '90%' }}>Name</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {catagories && catagories.map(category =>
                        <tr key={category.id}>
                            <td>{category.name}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/edit/${category.id}`} className="btn btn-sm btn-primary mr-1">Edit</Link>
                                <button onClick={() => deleteCategory(category.id)} className="btn btn-sm btn-danger btn-delete-user" disabled={category.isDeleting}>
                                    {category.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span>Delete</span>
                                    }
                                </button>
                            </td>
                        </tr>
                    )}
                    {!catagories &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {catagories && !catagories.length &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="p-2">No catagories to display</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}