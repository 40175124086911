import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {fetchRestaurantProducts, deleteRestaurantProductById} from '../../../api/restaurantApi'
import {withAsync} from '../../../api/helpers'

export default function List({ match }) {
    const { path } = match;
    //const [users, setUsers] = useState(null);
    const [products, setProducts] = useState(null);

    
    // useEffect(() => {
    //     userService.getAll().then(x => setUsers(x));
    // }, []);

    async function fetchProducts() {
        const {error, response } = await withAsync(fetchRestaurantProducts)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        console.log('===>  FETCHCAT RESPONSE', response)
        setProducts(response.data)
        // console.log(response, "<===== Category data")
    }

    useEffect( () => {
        fetchProducts()
    }, []);

    async function deleteProduct(id) {
        setProducts(products.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));

        const {error, response } = await withAsync(deleteRestaurantProductById, id)
        if (error) {
            console.log('error', error)
            return
        }
        console.log('THIS RESPONSE OF DELETE', response)
        setProducts(products => products.filter(x => x.id !== id));
    }

    return (
        <div>
            <h1>Products</h1>
            <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Add Product</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: '30%' }}>Name</th>
                        <th style={{ width: '30%' }}>Price</th>
                        <th style={{ width: '30%' }}>Category</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {products && products.map(product =>
                        <tr key={product.id}>
                            <td>{product.name}</td>
                            <td>€ {product.price}</td>
                            <td>{product.category_name}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>
                                <Link to={`${path}/edit/${product.id}`} className="btn btn-sm btn-primary mr-1">Edit</Link>
                                <button onClick={() => deleteProduct(product.id)} className="btn btn-sm btn-danger btn-delete-user" disabled={product.isDeleting}>
                                    {product.isDeleting 
                                        ? <span className="spinner-border spinner-border-sm"></span>
                                        : <span>Delete</span>
                                    }
                                </button>
                            </td>
                        </tr>
                    )}
                    {!products &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                        </tr>
                    }
                    {products && !products.length &&
                        <tr>
                            <td colSpan="4" className="text-center">
                                <div className="p-2">No products to display</div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}