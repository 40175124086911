import api from './api'

const URLS = {
    login: 'dj-rest-auth/login/',
    logout: 'dj-rest-auth/logout/',
}

export const login = (email, password) => {
    return api.post(URLS.login, {
        email,
        password
    })
}

export const logout = () => {
    localStorage.removeItem("token")
    return api.post(URLS.logout)    
}

export const setAuthToken = token => {
    localStorage.setItem("token", token)
    return api.setAuthToken(token)
}