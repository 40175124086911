
import {Component} from "react"


class OrderReceiptPrint extends Component {

    render() {
        // Dev temp
    const logo = require('../images/sinbad_logo.png').default
    //console.log('===> ORDERLIST IN RECEIPT', this.props.orderList)

    return (
    <div style={{ marginLeft: '100px'}}>
        <div>
            <img src={logo} alt="" />
        </div>
        <h1 style={{ marginLeft: '150px', fontSize: '125px', color:'black'}}>&nbsp;# {this.props.createdOrderId}</h1>
        { this.props.receiptList.map((product, i) => (
        <div key={i} style={{ marginTop: '100px'}}>
            <div style={{ fontSize: '75px', color:'black'}}>
                <span className="item__detail__qty">{product.qty}x</span>
                <span className="" style={{ marginLeft: '30px'}}>{product.name}</span >
                
            </div>
            <div className="" style={{ fontSize: '40px', color:'black'}}>
                {Object.entries(product.order_item_options).map(([optionId, option]) => (
                    <span className="" key={optionId}>+ {option.name}<br /></span>
                ))}
            </div>
        </div>
        ))}
    </div>
    );
  }
}

export default OrderReceiptPrint;