import React from 'react'

import { Form, Button } from 'react-bootstrap'
import { withAsync } from '../../api/helpers'
import {alertService} from '../../services';


export default function Login(props) {


    async function initLogout() {
        const { response, error } = await withAsync(logout)
        console.log('Logout', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        
        console.log('Logout success')
    }

    const logout = () => {
        initLogout();
        localStorage.clear();
        alertService.success('Uitgelogd', { keepAfterRouteChange: true });
        window.location = '/login'
    }

    return (
        <div id="Outer">
            <main id="ContentBox">
                <div className="app">
                    <div className="container h-100">
                        <div className="row mt-10 justify-content-center align-items-center">
                            <div className="col-10 col-md-8 col-lg-6 mt-5">
                                <h1>Logout Solufo</h1>
                                <p className="description">Solufo RMS</p>
                                <Form>
                                    <Button onClick={logout} >Logout</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div >
    );
}