import React, { createContext, useContext, useState } from "react";

export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext)
// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {

  const [name, setName] = useState("William");
  const [restaurant, setRestaurant] = useState(0);

  return (
    <UserContext.Provider
      value={{
        name,
        restaurant,
        setName,
        setRestaurant
      }}
    >
      {children}
    </UserContext.Provider>
  );
};