import React from 'react'

export default function Order({ categories, activeCategory, setActiveCategory }) {

    const catClick = id => {
        const newCat = categories.find(x => x.id === id)
        setActiveCategory(newCat)
        console.log('ActiveCat function: ', activeCategory )
    };

    return (
        < nav className="navbar navbar-expand-lg" >
            {/* <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="menu_icon">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </span>
            </button> */}
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    {categories.map((category) => (
                        <li key={category.id} className={activeCategory.id === category.id ? 'nav-item active' : 'nav-item'}>
                            < a className="nav-link" href="#" onClick={() => catClick(category.id)}>{category.name}</a>
                        </li>
                    ))}
                </ul>
                {/* <ul className="navbar-nav">
                    {categories.map((category) => (
                        <li key={category.id} className={activeCategory.id === category.id ? 'nav-item active' : 'nav-item'}>
                            < a className="nav-link" href="#" onClick={() => catClick(category.id)}>{category.name}</a>
                        </li>
                    ))}
                </ul> */}
            </div >
        </nav >
    )
}