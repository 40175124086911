import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from 'react-router-dom'

import { AppContext } from "./libs/contextLib";
import { UserProvider } from './contexts/UserContext'

// import { Home, ActiveOrders, Login, Logout } from './pages'
import Home from './views/Home'
import Login from './views/login/Login'
import Logout from './views/logout/Logout'
import ActiveOrders from './views/order/ActiveOrders'
import ReadyOrders from './views/order/ReadyOrders'
import Admin from './views/admin/Admin'

const privateRoute = (Component) => props => {
  let token = localStorage.getItem("token")
  useEffect(() => {
    token = localStorage.getItem("token")
    console.log('token check', token, props)
    if (!token) props.history.push('/login')
  }, [])
  return token && <Component {...props} />
}

function App() {

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    //console.log('isAuthenticated = ', isAuthenticated)

    const token = localStorage.getItem("token")
    console.log('token = ', token)

    return (
        <div>
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
            <UserProvider>
            <Switch>

                <Route path="/login" 
                render={(props) => {
                    if(!token){
                        return <Login {...props}/>
                    } else{
                        return <Logout />
                    }
                }}
                />  

                <Route path="/logout"  
                render={() => {
                   if(token){
                       return <Logout />
                   }else{
                       return <Redirect to='/login' />
                   }
                }}
                />

              <Route path="/admin" component={privateRoute(Admin)} />
              <Route path="/home" component={privateRoute(Home)} />
              <Route path="/active-orders" component={privateRoute(ActiveOrders)} />
              <Route path="/ready-orders" component={privateRoute(ReadyOrders)} />
              <Route path="/" component={privateRoute(Home)} />
              
            </Switch >
                 </UserProvider>
            </AppContext.Provider>
        </div >
    )
}

export default App
