import { useState, useEffect, useRef } from 'react'
import { cloneDeep } from 'lodash-es'
import { useReactToPrint } from 'react-to-print';

import { Modal, Button } from 'react-bootstrap'

import OrderItem from './OrderItem'
import OrderReceipt from './OrderReceipt'
import OrderReceiptPrint from './OrderReceiptPrint'
import { withAsync } from '../api/helpers'
import { postOrder } from '../api/restaurantApi'

export default function Order({ orderList, setOrderList, }) {

    const [totalPrice, setTotalPrice] = useState(0)
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [createdOrderId, setCreatedOrderId] = useState(0)
    const [receiptList, setReceiptList] = useState([])


    const orderModalOpen = () => setShowOrderModal(true);
    const orderModalClose = () => {
        setShowOrderModal(false)
        console.log(orderList, "order list <======")
    };
    
    // Pricing
    // useEffect on orderList
    useEffect(() => {
        const totalPrice = orderList.reduce((total, obj) => Number(obj.totalPrice * obj.qty) + Number(total), 0).toFixed(2)
        setTotalPrice(totalPrice)
    }, [orderList])

    const componentRef = useRef();
    const handlePrint = useReactToPrint(
        {
        content: () => componentRef.current,
        }
    )

    const onSubmitOrder = async () => {
        //console.log('SUBMIT ORDER')
        //console.log('order list', orderList)
        try {
            const order_items = orderList.map((item) => {
                const {product, qty, order_item_options, totalPrice} = item

                return {
                    product,
                    qty,
                    price: totalPrice,
                    discount_price: 0.00,
                    order_item_options: Object.values(order_item_options).map(({id}) => ({product_option: id}))
                }
            })

            console.log({order_items})
            const payload = {
                restaurant: 2,
                status: 'created',
                order_items
            }
            
            console.log('===> PAYLOAD', {payload})
            
            // make api request
            const {error, response } = await withAsync(postOrder, payload)
            //console.log('Fetch products response', response, error)
            if (error) {
                console.log('error', error)
                return
            } else {
                console.log('===> RESPONSE', response)
                //console.log('===> RESPONSE DATA', response.data.id)
                
                console.log('===> RESPONSE DATA', response.data.id)
                

            }

            console.log('===> createdOrderId', createdOrderId)
            setCreatedOrderId(response.data.id)
            setReceiptList(orderList)
            handlePrint()
            orderModalClose()
            console.log('RECEIPTLIST', receiptList)
            setOrderList([])

            

        } catch (error) {
            console.error(error)
        }
    }


    // Dev temp
    const logo = require('../images/logo.jpg').default
    

    return (
        <>
        <div className="app__left">
            <div className="app__left__sticktop">
                <div className="app__left__logo">
                    <a href="#"><img src={logo} alt="" /></a>
                </div>
                <h3 className="app__left__bestelling">Bestelling</h3>
                <div className="app__left__sell" id="bestelling">
                    {!orderList.length ?
                        <>
                            <div className="app__left__sell__item">
                                <div className="app__left__sell__item__detail">
                                    <span className="app__left__sell__item__detail__name">No products added yet</span>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {
                                orderList.map((product, i) => (

                                    <OrderItem key={product.orderProductId} orderList={orderList} setOrderList={setOrderList} productIndex={i} {...product} />
                                ))
                            }
                        </>
                    }
                </div>
            </div>
            <div className="app__left__stickbottom">
                <div className="app__left__total">
                    <h4>Totaal Betalen</h4>
                    <span className="app__left__total__price">{totalPrice}</span>
                    <a href="#" onClick={orderModalOpen} className="btn btn-primary">Bestellen</a>
                </div>
                <footer className="app__left__copyright">
                    <p>&copy; 2020 Solufo RMS</p>
                </footer>
            </div>
        </div>
    
        <Modal show={showOrderModal} onHide={orderModalClose} className="" tabIndex="-1" aria-labelledby="mymodallLabel" aria-hidden="true">
            <Modal.Header closeButton>
            <Modal.Title>Bestelling afronden?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="secondary" onClick={orderModalClose}>
                Annuleren
            </Button>
            <Button variant="primary" onClick={onSubmitOrder}>
                Bevestigen + Print bon
            </Button>
            <Modal.Body>
                <OrderReceipt orderList={orderList} totalPrice={totalPrice}/> 
            </Modal.Body>
            </Modal.Footer>
        </Modal >
        <div style={{display:"none"}}>
            <OrderReceiptPrint receiptList={receiptList} orderList={orderList} totalPrice={totalPrice} createdOrderId={createdOrderId}  ref={componentRef} className="PrintSection"/> 
        </div>
    </>
    )
} 