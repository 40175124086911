import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Nav() {
    return (
        <nav className="navbar navbar-expand navbar-dark bg-dark">
            <div className="navbar-nav">
                <NavLink to="/admin" className="nav-item nav-link navbar-brand">Solufo RMS</NavLink>
                <NavLink to="/admin/catagories" className="nav-item nav-link">Catagories</NavLink>
                <NavLink to="/admin/products" className="nav-item nav-link">Products</NavLink>
                <NavLink to="/admin/product-options" className="nav-item nav-link">Product options</NavLink>
                <NavLink to="/admin/orders" className="nav-item nav-link">Orders</NavLink>
            </div>
        </nav>
    );
}