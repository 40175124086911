import axios from 'axios'
const mytoken = localStorage.getItem("token")

const axiosParams = {
    baseURL: process.env.NODE_ENV === 'production' ? 'https://rms-production.solufo-services.com/api/v1' : 'https://rms-production.solufo-services.com/api/v1',
    //baseURL: process.env.NODE_ENV === 'development' ? 'https://rms-stage.solufo-services.com/api/v1' : 'https://rms-stage.solufo-services.com/api/v1',
    //baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : 'https://rms-production.solufo-services.com/api/v1',
    // headers: {
    //     // common: {

    //     // }
    // }
}

// const header = {
//     headers: {
//         "Authorization": 'Token ' + mytoken
//     }
// }

const axiosInstance = axios.create(axiosParams)

const setAuthToken = (token) => axiosInstance.defaults.headers.Authorization = 'Token ' + token

//console.log('mytoken?', mytoken)
mytoken && setAuthToken(mytoken)

const api = (axiosInstance) => {
    return {
        get: (url, config = {}) => axiosInstance.get(url, config),
        getwtoken: (url, config = {}) => axiosInstance.get(url, config),
        post: (url, body, config = {}) => axiosInstance.post(url, body, config),
        postwtoken: (url, body, config = {}) => axiosInstance.post(url, body, config),
        patch: (url, body, config = {}) => axiosInstance.patch(url, body, config),
        patchForm: (url, body, config = {headers: {'Content-Type': 'multipart/form-data'
        }}) => axiosInstance.put(url, body, config),
        patchwtoken: (url, body, config = {}) => axiosInstance.patch(url, body, config),
        putwtoken: (url, body, config = {}) => axiosInstance.put(url, body, config),
        delete: (url, config = {}) => axiosInstance.delete(url, config),
        deletewtoken: (url, body, config = {}) => axiosInstance.delete(url, body, config),
        // setAuthToken: token => axiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null
        //setAuthToken: token => axiosInstance.defaults.headers.common.Authorization = token
        setAuthToken
    }
}

export default api(axiosInstance)