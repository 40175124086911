import React from 'react'
import { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { cloneDeep } from 'lodash-es'
import { withAsync } from '../../api/helpers'

import {fetchOrdersWithStatus, patchOrder} from '../../api/restaurantApi'

export default function ReadyOrders() {

    // Fetch order data
    const [activeOrders, setActiveOrders] = useState([]);
    const [finishedOrders, setFinishedOrders] = useState([]);
    const [date,setDate] = useState(new Date());

    async function fetchActiveOrders() {
        const status = 'created'
        const {error, response } = await withAsync(fetchOrdersWithStatus, status)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        setActiveOrders(response.data)
    }

    async function fetchFinishedOrders() {
        const status = 'ready'
        const {error, response } = await withAsync(fetchOrdersWithStatus, status)
        //console.log('Fetch categories response', response, error)
        if (error) {
            console.log('error', error)
            return
        }
        setFinishedOrders(response.data)
    }

    useEffect( () => {
        fetchActiveOrders()
        fetchFinishedOrders()
        const interval = setInterval(fetchFinishedOrders, 15000)
        const interval2 = setInterval(fetchActiveOrders, 15000)
        return () => clearInterval(interval, interval2)
    }, []);

    useEffect(() => {
        const timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });

    const logo = require('../../images/logo.jpg').default
    const clientLogo = require('../../images/sinbad_logo.png').default

    //Logs
    //console.log('===> ORDERS', orders)

    return (
        <div id="Outer">
            <main id="ContentBox">
                <header className="header" style={{ backgroundColor: '#fff'}}>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="header__logo">
                                <h1>Orders</h1>
                            </div>
                            <div className="header__active">
                                <img src={clientLogo} style={{ height: '130px'}}alt="" />
                            </div>
                            <div className="header__copyright">
                                <h2>{date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</h2>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="order-display">
                    <div className="container-fluid">
                        <div className="row mt-5 text-center">
                            <div className="col-md-6 order-border">
                                <h1>Processing</h1>

                                <div className="d-flex flex-wrap justify-content-center inline-block">   
                                    {activeOrders.map((order, i) => (
                                        <div className="orderCircle" key={i}>{order.id}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h1>Ready to pick-up</h1>
                                <div className="d-flex justify-content-center">   
                                    {finishedOrders.map((order, i) => (
                                        <div className="orderCircleGreen" key={i}>{order.id}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div >
    )
} 