import api from './api'

const URLS = {
    users : 'restaurant/users',
    restaurantCategories: '/restaurant/categories/',
    restaurantProducts: '/restaurant/products/',
    restaurantCrudProducts: '/restaurant/crud-products/',
    restaurantProductOptions : '/restaurant/product-options/',
    restaurantOrders: '/restaurant/orders/',
    restaurantOrdersWithStatus: '/restaurant/orders/?status=',
}


// User
export const fetchUser = () => {
    return api.get(URLS.users)
}

// Categories
export const fetchCategories = () => {
    return api.get(URLS.restaurantCategories)
}

export const fetchRestaurantCategories = () => {
    return api.get(URLS.restaurantCategories)
}

export const postRestaurantCategories = (payload) => {
    return api.postwtoken(URLS.restaurantCategories, payload)
}

export const patchRestaurantCategories = (categoryId, payload) => {
    return api.patchwtoken(URLS.restaurantCategories + categoryId + '/', payload)
}

export const fetchRestaurantCategoryById = (categoryId) => {
    return api.getwtoken(URLS.restaurantCategories + categoryId + '/')
}

export const deleteRestaurantCategoryById = (categoryId) => {
    return api.deletewtoken(URLS.restaurantCategories + categoryId + '/')
}

// Products
export const fetchRestaurantProducts = () => {
    return api.get(URLS.restaurantProducts)
}

export const postRestaurantProducts = (payload) => {
    return api.postwtoken(URLS.restaurantProducts, payload)
}

export const patchRestaurantProducts = (productId, payload) => {
    return api.patchwtoken(URLS.restaurantProducts + productId + '/', payload)
}

export const fetchRestaurantProductById = (productId) => {
    return api.getwtoken(URLS.restaurantProducts + productId + '/')
}

export const deleteRestaurantProductById = (productId) => {
    return api.deletewtoken(URLS.restaurantProducts + productId + '/')
}

// Products ADMIN
export const fetchRestaurantCrudProducts = () => {
    return api.get(URLS.restaurantCrudProducts)
}

export const postRestaurantCrudProducts = (payload) => {
    return api.postwtoken(URLS.restaurantCrudProducts, payload)
}

export const patchRestaurantCrudProducts = (productId, payload) => {
    return api.patch(URLS.restaurantCrudProducts + productId + '/', payload)
}

// export const putRestaurantCrudProducts = (productId, payload) => {
//     return api.putwtoken(URLS.restaurantCrudProducts + productId + '/', payload)
// }

export const fetchRestaurantCrudProductById = (productId) => {
    return api.getwtoken(URLS.restaurantCrudProducts + productId + '/')
}

export const deleteRestaurantCrudProductById = (productId) => {
    return api.deletewtoken(URLS.restaurantCrudProducts + productId + '/')
}


// Product Options
export const fetchRestaurantProductOptions = () => {
    return api.get(URLS.restaurantProductOptions)
}

export const postRestaurantProductOptions= (payload) => {
    return api.postwtoken(URLS.restaurantProductOptions, payload)
}

export const patchRestaurantProductOptions = (productOptionId, payload) => {
    return api.patchwtoken(URLS.restaurantProductOptions + productOptionId + '/', payload)
}

export const fetchRestaurantProductOptionById = (productOptionId) => {
    return api.getwtoken(URLS.restaurantProductOptions + productOptionId + '/')
}

export const deleteRestaurantProductOptionById = (productOptionId) => {
    return api.deletewtoken(URLS.restaurantProductOptions + productOptionId + '/')
}

// Orders
export const fetchOrders = () => {
    return api.get(URLS.restaurantOrders)
}

export const fetchOrdersWithStatus = (status) => {
    return api.get(URLS.restaurantOrdersWithStatus + status)
}

export const fetchOrderById = (orderId) => {
    return api.getwtoken(URLS.restaurantOrders + orderId + '/')
}

export const postOrder = (payload) => {
    return api.postwtoken(URLS.restaurantOrders, payload)
}

export const patchOrder = (orderId, payload) => {
    return api.patchwtoken(URLS.restaurantOrders + orderId + '/', payload)
}

export const deleteOrderById = (orderId) => {
    return api.deletewtoken(URLS.restaurantOrders + orderId + '/')
}

export const deleteAllOrders = () => {
    return api.getwtoken(URLS.restaurantOrders + 'delete_all')
}
